<template>
  <div>
    <table
      id="chartHistoryReport"
      class="table table-condensed table-bordered table-hover dataTable table-striped  table-responsive"
    >
      <tbody>
        <tr>
          <td class="text-center">{{ $t("no_result_found") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "EquipmentChartHistoryNoData"
};
</script>

<style scoped></style>
