<template>
  <span />
</template>

<script>
import { debounce } from "lodash";
import EventLogService from "@/services/event-log.js";
export default {
  name: "EquipmentDisconnectionLogger",
  props: {
    panelOptions: {
      type: Object,
      required: true,
      default: () => null
    },
    namespace: {
      type: String,
      required: false,
      default: "history"
    }
  },
  data() {
    return {
      busy: false,
      connectionLog: null,
      historyInterval: null
    };
  },
  computed: {
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    contractId() {
      return this.$store.getters["user/contract"]?.id || null;
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || []
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || []
    },
    disconnectionEnabled() {
      return this.mode == "editor" ? false : this?.panelOptions?.showDisconnection ?? false;
    },
    disconnectedDataLine() {
      return this?.panelOptions?.disconnectedDataLine ?? "trend";
    },
    disconnectionThreshold() {
      return parseInt(this?.panelOptions?.disconnectionThreshold ?? "0");
    },
    disconnection() {
      if (!this.disconnectionEnabled || !this.connectionLog || this.mode == "editor") return null;
      let entry = null;
      let history = this.$store.getters[`${this.namespace}/entries`] || {};
      let max = undefined;
      let hasFloatOrInt = false;
      for (var dataId in history) {
        let data = this.dataList.find(({ id }) => parseInt(id) == parseInt(dataId));
        if (!hasFloatOrInt && data.type == 'float') hasFloatOrInt = true;
        max = max === undefined || history[dataId]?.stats?.maximum > max ? history[dataId]?.stats?.maximum : max;
      }
      if (max == undefined || 0) max = hasFloatOrInt ? 100 /* float or int */ : 1 /* boolean */;
      Object.keys(this.connectionLog || {}).forEach((connId) => {
        if (!this.connectionLog[connId]) return;
        entry = entry || {
          ini: this.historyInterval.start._d.toISOString(),
          end: this.historyInterval.end._d.toISOString(),
          color: this?.panelOptions?.disconnectionColor ?? "#FFFFFF",
          dataLine: this.disconnectedDataLine,
          items: {}
        };
        entry.items[connId] = entry.items[connId] || [];
        (this.connectionLog[connId] || []).forEach((item) => {
          if (((new Date(item.end).getTime() - new Date(item.ini).getTime()) / 1000) > this.disconnectionThreshold) {
            entry.items[connId].push(
              {
                ini: item.ini,
                end: item.end,
                value: max,
                connectorName: this.connectorList.find(({ id }) => parseInt(id) == parseInt(connId))?.name || "-"
              }
            )
          }
        })
      });
      return entry;
    }
  },
  methods: {
    // ids = connector id
    fetchDisconnection(ids, historyInterval) {
      if (this.mode == "editor") {
        this.$emit("ready", false);
        this.$nextTick(() => {
          this.$emit("ready", true);
        });
        return;
      };
      this._delayFetch(ids, historyInterval);
    }
  },
  beforeCreate() {
    const _fetchDisconnection = (ids, historyInterval) => {
      return new Promise((resolve) => {
        if (!this.disconnectionEnabled || this.busy) {
          resolve(null);
          return;
        }
        this.historyInterval = historyInterval;
        let query = {
          contract_id: this.contractId,
          start: moment(this.historyInterval.start)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          end: moment(this.historyInterval.end)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          connector_id: ids[0],
          system_event_id: 2
        };
        let result = {};
        this.busy = true;
        this.svr_event.fetch(query).then((resp) => {
          this.busy = false;
          if (!resp || !resp.length) {
            resolve(null);
            return;
          };
          resp = resp
            .map((i) => { i.date_time_event = new Date(i.date_time_event).toISOString(); return i })
            .sort((a, b) => a.date_time_event > b.date_time_event ? 1 : b.date_time_event > a.date_time_event ? -1 : 0)
          let entry = {};
          resp
            .filter(({ system_sub_event_id }) => system_sub_event_id == 2 || system_sub_event_id == 3)
            .forEach((event, ix) => {
              entry[event.connector.id] = entry[event.connector.id] || {};
              if (event.system_sub_event_id == 3) {
                // disconnected
                entry[event.connector.id].ini = event.date_time_event;
                if (ix == resp.length - 1) {
                  entry[event.connector.id].end = this.historyInterval.end._d.toISOString();
                }
              } else {
                // connected
                if (!entry[event.connector.id].ini) {
                  entry[event.connector.id].ini = this.historyInterval.start._d.toISOString();
                }
                entry[event.connector.id].end = event.date_time_event;
              }
              if (entry[event.connector.id].ini && entry[event.connector.id].end) {
                result = result || {};
                result[event.connector.id] = result[event.connector.id] || [];
                result[event.connector.id].push(entry[event.connector.id]);
                delete entry[event.connector.id];
              }
            });
          this.$set(this, "connectionLog", result);
          resolve(result);
        });
      });
    };
    this._delayFetch = debounce((ids, historyInterval) => {
      this.$emit("ready", false);
      _fetchDisconnection(ids, historyInterval).then(() => {
        this.$emit("ready", true);
      });
    }, 100);
  },
  created() {
    this.svr_event = new EventLogService();
  },
  beforeDestroy() {
    this.svr_event = null;
  }
}
</script>
